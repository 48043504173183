import React from "react";

const Education = () => {
  const educationData = [
    {
      period: "SEPTEMBER, 2023 - OCTOBER, 2024",
      title: "MSc in Computer Science @ University of Bath",
      modules:
        "Principles of Programming (Java, Python), database, Software Engineering, AI.",
    },
    {
      period: "AUGUST, 2019 - FEBRUARY, 2020",
      title:
        "Post Graduate Diploma in Advanced Computing @ Centre for Development of Advanced Computing (CDAC) ",
      modules:
        "C, C++, Object Oriented Programming (OOPs), Algorithms and Data Structures, Java, .NET, Advanced Web Programming, PHP, MEAN Stack, Database Technologies.",
    },
    {
      period: "JULY, 2015 - MAY, 2019",
      title:
        "Bachelor of Engineering in Electronics and Telecommunications  @ Pune Institute of Computer Technology (PICT), Pune. ",
    },
  ];

  return (
    <div className="education-timeline bg-gray-900 text-white p-8 rounded-md max-w-3xl mx-auto">
      <h2 className="text-3xl font-bold mb-6">Education</h2>
      <div className="relative border-l-4 border-indigo-700 pl-8">
        {educationData.map((item, index) => (
          <div key={index} className="mb-10 relative">
            <div className="absolute -left-11 top-0 w-6 h-6 rounded-full bg-indigo-500 border-4 border-gray-900"></div>
            <div className="mb-2 text-sm text-gray-400">{item.period}</div>
            <h3 className="text-xl font-bold mb-2">{item.title}</h3>
            {item.modules && (
              <p className="text-sm">
                <span className="text-lg font-bold">Relevant Modules:</span>{" "}
                {item.modules}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Education;
