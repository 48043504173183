import React from "react";
import usePageViews from "./usePageViews";

const ProfileCard = () => {
  usePageViews();
  return (
    <div className="profile-card max-w-xs mx-auto bg-gray-900 text-white rounded-lg shadow-lg transition-transform transform hover:scale-105">
      {/* Profile Image Section (optional) */}
      {/* 
      <div className="relative h-32 bg-purple-700">
        <img
          className="absolute top-1/2 left-1/2 w-24 h-24 rounded-full object-cover transform -translate-x-1/2 -translate-y-1/2 border-4 border-gray-900"
          src="https://via.placeholder.com/150" // Replace with actual image URL
          alt="Profile"
        />
      </div> 
      */}
      <div className="text-center p-4">
        <h2 className="text-2xl font-bold">Minal Kulkarni</h2>
        <p className="text-gray-400 mt-1">
          Front-end Developer &middot; Coventry, United Kingdom.
        </p>
        <p className="mt-4 text-gray-300">
          I'm Minal Kulkarni, I am currently pursuing an MSc in Computer Science
          from the University of Bath. I am an experienced Front-End Software
          Engineer with 3 years of expertise in developing dynamic web
          applications using React, JavaScript, HTML, CSS, and Node.js by
          collaborating with diverse teams.
        </p>
      </div>
    </div>
  );
};

export default ProfileCard;
