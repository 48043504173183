import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";
import ProfileCard from "./ProfileCard";
import SkillsCard from "./SkillsCard";
import NavBar from "./NavBar";
import React, { useState } from "react";
import Projects from "./Projects";
import Experience from "./Experience";
import Education from "./Education";
import Contact from "./Contact";

function App() {
  const [activeSection, setActiveSection] = useState("projects");

  React.useEffect(() => {
    ReactGA.initialize("G-L8CVXD47J5");
  }, []);

  return (
    <Router>
      <div className="min-h-screen bg-[#1a1a2e] flex flex-col lg:flex-row lg:p-10 p-6 gap-6">
        {/* Profile and Skills Section */}
        <div className="flex flex-col w-full lg:w-1/3 gap-6 lg:gap-8">
          <ProfileCard />
          <SkillsCard />
        </div>

        {/* Main Content Section */}
        <div className="flex flex-col w-full lg:w-2/3 gap-6 lg:gap-8">
          <NavBar
            activeSection={activeSection}
            setActiveSection={setActiveSection}
          />
          <div className="bg-[#24243e] text-[#e4e4e7] p-6 rounded-lg w-full shadow-lg">
            <Routes>
              <Route path="/" element={<Projects />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/experience" element={<Experience />} />
              <Route path="/education" element={<Education />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
