import React from "react";
import { Link } from "react-router-dom";

const projectsList = [
  {
    title:
      "Digital Donor Engagement: The Impact of Motivational Messages and AI-Created Content on Monetary Donations (MSc Dissertation)",
    date: "June 2024 - Aug 2024",
    description:
      "Explored the effectiveness of motivational messages and AI-generated content to enhance donor engagement and increase donations on online fundraising platforms. The project involved building a full-stack web application, conducting A/B testing, and analyzing donor behavior through survey.",

    technologies:
      "React (React.js), Redux, React Hooks, Node.js, Sequelize, MySQL, JWT authentication, Heroku, ChatGPT, Formik, Bootstrap.",
  },
  {
    title: "Shopping Application",
    date: "Feb 2021",
    description:
      "Built a basic Shopping Application to learn React, in which the user can Register, Login (All validations applied), Shop, Edit Profile, view order Summary, view cart, etc.",
    link: "https://github.com/minalk97/shopping-application",
    technologies:
      "React (React.js), React Hooks, Lodash, Styled Components, local and session storage.",
  },
  {
    title: "Bestify your time",
    date: "Dec 2020",
    description:
      "The website helps break boredom and evolve simultaneously by offering brain teasers, logical thinking quizzes, general knowledge quizzes, and single-player game zones. Users can enjoy, learn, and best utilize time during lockdown.",

    technologies: "Angular, Node.js, MySQL, Bootstrap, RWD.",
  },
  {
    title: "Gym Management System (PG-DAC Project)",
    date: "Dec 2019 - Jan 2020",
    description:
      "Gym Management System is a web-based application offering a user-friendly interface to trainers, managers, and gym members. The system automates the processes and activities of a gym. Trainers can keep track of allocated members' progress, update exercise schedules, and provide diet plans for members assigned by the manager.",
    link: "https://github.com/minalk97/gym-management-system",
    technologies: "Java, Spring Boot REST API, JPA, Angular, Bootstrap, MySQL.",
  },
];

const Projects = () => {
  return (
    <div className="education-timeline bg-gray-900 text-white p-8 rounded-md custom-width">
      <h2 className="text-3xl font-bold mb-6">Projects</h2>
      <div className="relative border-l-4 border-gray-700 pl-8"></div>
      <section className={`my-2 transition-colors duration-300`}>
        <div className="container mx-auto px-4 md:px-0 max-w-full lg:max-w-5xl">
          {/* Breadcrumb */}

          <div className="space-y-8">
            {projectsList.map((project, index) => (
              <div key={index} className="border-b-2 border-gray-300 pb-4">
                <h3 className="text-2xl font-semibold dark:text-rgb(255 255 254/var(--tw-text-opacity))-400">
                  {project.link ? (
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={project.link}
                      className="hover:underline"
                    >
                      {project.title}
                    </Link>
                  ) : (
                    <>{project.title}</>
                  )}
                </h3>
                <p className="text-sm text-gray-500">{project.date}</p>
                <p className="mt-2">{project.description}</p>
                <p className="mt-2">
                  <span className="text-lg">Technologies:</span>{" "}
                  {project.technologies}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Projects;
