import React from "react";
import { Link } from "react-router-dom";

const NavBar = ({ activeSection, setActiveSection }) => {
  return (
    <div className="navbar flex flex-wrap justify-center gap-4 bg-gray-900 p-4 rounded-md w-full">
      {["projects", "experience", "education", "contact"].map((section) => (
        <Link
          key={section}
          to={`/${section}`}
          className={`text-white px-6 py-2 rounded-md font-semibold focus:outline-none transition ${
            activeSection === section ? "bg-indigo-500" : "hover:bg-gray-800"
          }`}
          onClick={() => setActiveSection(section)}
        >
          {section.toUpperCase()}
        </Link>
      ))}
    </div>
  );
};

export default NavBar;
