import React from "react";

const Contact = () => {
  return (
    <div className="education-timeline bg-gray-900 text-white p-8 rounded-md custom-width">
      <div className="relative border-l-4 border-gray-700 pl-8"></div>
      <div className="content-section">
        <h2 className="text-2xl font-bold mb-4">Get in touch</h2>
        <p>
          I'm always open to new opportunities and collaborations. Feel free to
          reach out to me on{" "}
          <a
            href="https://www.linkedin.com/in/minal-kulkarni-39586811b/"
            className="underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>{" "}
          or email me at{" "}
          <a href="mailto:minalk97@gmail.com" className="underline">
            minalk97@gmail.com
          </a>
          .
        </p>
        <div className="mt-6 transition transform duration-200 ease-in-out hover:scale-105">
          <a
            href={`${process.env.PUBLIC_URL}/Minal_Kulkarni_Resume.pdf`}
            download="Minal_Kulkarni_Resume.pdf"
            className="mt-4 bg-indigo-500 text-white px-6 py-2 rounded-md font-semibold focus:outline-none"
          >
            DOWNLOAD RESUME
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
