import React from "react";

const skills = [
  "REACT.JS",
  "JAVASCRIPT",
  "TYPESCRIPT",
  "ANGULAR",
  "NODE.JS",
  "MYSQL",
  "CSS",
  "HTML",
];

const SkillsGrid = () => {
  return (
    <div className="bg-gray-900 text-white p-6 rounded-lg shadow-lg w-full">
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-2 gap-4">
        {skills.map((skill, index) => (
          <div
            key={index}
            className="skills-card-item flex items-center justify-center bg-gray-800 p-4 rounded-md text-base font-semibold text-purple-200 transition-transform transform hover:scale-105"
          >
            {skill}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SkillsGrid;
