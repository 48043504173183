import React from "react";

const Experience = () => {
  return (
    <div className="education-timeline bg-gray-900 text-white p-8 rounded-md custom-width">
      <h2 className="text-3xl font-bold mb-6">Experience</h2>
      <div className="relative border-l-4 border-gray-700 pl-8"></div>
      <div className="content-section custom-width">
        <div className="mb-12">
          <div className="text-xl text-left ">
            Software Engineer at Cybage Software Pvt. Ltd.
          </div>
          <div className="text-lg text-left">
            Client: Classy (now a part of GoFundMe)
          </div>
          <div className="mb-2 text-sm text-gray-400 mb-5 text-left">
            September, 2020 – August, 2023
          </div>

          <ul className="list-disc mt-2 pl-8 text-left">
            <li>
              Developed customer-facing web applications using React and
              TypeScript for Classy, a leading fundraising platform acquired by
              GoFundMe.
            </li>
            <li>
              Translated the design team's UX mockups (Figma) into responsive,
              interactive features, for Admin Dashboard.
            </li>
            <li>
              Integrated different APIs using GraphQL query language and Apollo
              in web applications.
            </li>
            <li>
              Translated the design team's UX mockups into responsive,
              interactive features, for the Admin Dashboard for the fundraising
              website.
            </li>
            <li>
              Migrated features from Angular to React, resulting in a 30%
              performance improvement.
            </li>
            <li>
              Independently integrated a Salesforce chatbot into the project
              enhancing user interaction and support.
            </li>
            <li>
              Conducted thorough code reviews to ensure code quality,
              extensibility, and reusability, resulting in a 15% reduction in
              post-deployment issues.
            </li>
            <li>
              Collaborated with cross-functional teams to enhance product
              functionality and improve user experience.
            </li>
            <li>
              Followed Agile (SCRUM) methodologies to manage and deliver
              projects on time.
            </li>
            <li>
              Integrated web accessibility to Angular and React Components.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Experience;
